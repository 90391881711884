<aside
  class="main-sidebar sidebar-dark-primary elevation-4"
  *ngIf="isLoggedIn$ | async as isLoggedIn"
>
  <!-- Brand Logo -->
  <a routerLink="home" class="brand-link">
    <!-- <span class="brand-text font-weight-light">SAESA</span> -->
    <img src="../../../../../assets/img/logosidebar.png" width="150"/>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <i style="font-size: 32px; color: white" class="fas fa-user-circle"></i>
      </div>
    
      <a class="nav-link">
        <i class=""></i>
        <p style="font-size: 18px;">
          {{obtieneNombre()}} 
          <i class=""></i>
        </p>
        <p>
          {{obtieneRol()}} 
          <i class=""></i>
        </p>
         </a>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li
          *ngIf="listaDenuncia.length > 0"
          class="nav-item has-treeview"
          routerLinkActive="active"
        >
          <a class="nav-link">
            <i class=""></i>
            <p>
              Denuncias
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" *ngFor="let item of listaDenuncia">
            <li class="nav-item">
              <a
                routerLink="{{ item.url }}"
                class="nav-link"
                routerLinkActive="active"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>{{ item.nombre }}</p>
              </a>
            </li>
          </ul>
        </li>

        <!-- funcionalidad de denuncias-->
        <li
          *ngIf="listaMantenedor.length > 0"
          class="nav-item has-treeview"
          routerLinkActive="active"
        >
          <a class="nav-link">
            <i class=""></i>
            <p>
              Mantenedores
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" *ngFor="let item of listaMantenedor">
            <li class="nav-item">
              <a
                routerLink="{{ item.url }}"
                class="nav-link"
                routerLinkActive="active"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>{{ item.nombre }}</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- funcionalidad de mantenedores -->

        <li
          *ngIf="listaReporte.length > 0"
          class="nav-item has-treeview"
          routerLinkActive="active"
        >
          <a class="nav-link">
            <i class=""></i>
            <p>
              Reportes
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" *ngFor="let item of listaReporte">
            <li class="nav-item">
              <a
                routerLink="{{ item.url }}"
                class="nav-link"
                routerLinkActive="active"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>{{ item.nombre }}</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- funcionalidad de reportes-->
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
    <!-- /.sidebar -->

    <!-- <div class="aside-footer">
      <div
        class="footer text-center py-3"
        style="font-size: 16px; color: white"
      >
        Versión 1.0
      </div>
    </div> -->
  </div>
</aside>

<!-- Main Sidebar Container -->
