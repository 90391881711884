import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertaModel } from '../Models/alerta.model';
import { CuestionarioModel } from '../Models/cuestionario.model';
import { ProductoModel } from '../Models/producto.model';
import { TerminoModel } from '../Models/termino.model';
import { UsuarioMantenedor } from '../Models/usuario.model';
import { Assing, Mapper } from '../shared/Helpers/helper';

@Injectable({
  providedIn: 'root',
})
export class ProductoService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiUrl}Test`;
  }

  listar() {
    let query = `${this.url}`;
    return this.http
      .get(query)
      .pipe(map((data) => Mapper<ProductoModel>(data)));
  }

  listarUsuarios() {
    let query = `${this.url}`;
    return this.http
      .get(query)
      .pipe(map((data) => Mapper<UsuarioMantenedor>(data)));
  }

  listarCuestionarios() {
    let query = `${this.url}`;
    return this.http
      .get(query)
      .pipe(map((data) => Mapper<CuestionarioModel>(data)));
  }

  listarTerminos() {
    let query = `${this.url}`;
    return this.http
      .get(query)
      .pipe(map((data) => Mapper<TerminoModel>(data)));
  }

  listarAlertas() {
    let query = `${this.url}`;
    return this.http
      .get(query)
      .pipe(map((data) => Mapper<AlertaModel>(data)));
  }

  obtenerPorId(codigo: number) {
    let query = `${this.url}/${codigo}`;

    return this.http.get(query).pipe(map((res) => Assing<ProductoModel>(res)));
  }

  obtenerPorIdUsuario(codigo: number) {
    let query = `${this.url}/${codigo}`;
    return this.http.get(query).pipe(map((res) => Assing<UsuarioMantenedor>(res)));
  }

  guardar(item: ProductoModel) {
    let query = `${this.url}`;
    if (item.Id > 0) {
      query += `/${item.Id}`
      return this.http.put(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    } else {
      return this.http.post(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    }
  }

  guardarUsuario(item: UsuarioMantenedor) {
    let query = `${this.url}`;
    if (item.id > 0) {
      query += `/${item.id}`
      return this.http.put(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    } else {
      return this.http.post(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    }
  }
  guardarAlerta(item: AlertaModel) {
    // let query = `${this.url}`;
    // if (item.Id > 0) {
    //   query += `/${item.Id}`
    //   return this.http.put(query, item).pipe(
    //     map((data: any) => data),
    //     catchError((_) => of('Sin respuesta'))
    //   );
    // } else {
    //   return this.http.post(query, item).pipe(
    //     map((data: any) => data),
    //     catchError((_) => of('Sin respuesta'))
    //   );
    // }
  }

  guardarCuestionario(item: CuestionarioModel) {
    let query = `${this.url}`;
    if (item.id > 0) {
      query += `/${item.id}`
      return this.http.put(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    } else {
      return this.http.post(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    }
  }

  guardarTermino(item: TerminoModel) {
    let query = `${this.url}`;
    if (item.id > 0) {
      query += `/${item.id}`
      return this.http.put(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    } else {
      return this.http.post(query, item).pipe(
        map((data: any) => data),
        catchError((_) => of('Sin respuesta'))
      );
    }
  }

  eliminar(item : AlertaModel) {
    // let query = `${this.url}/${id}`;

    // return this.http.delete(query).pipe(
    //   map((data: any) => data),
    //   catchError((_) => of('Sin respuesta'))
    // );
  }
}
