import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';

import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'proyectobaseangular';

  constructor(private _router: Router){
    this._router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          // if(event.url == "/login"){
          //   document.body.classList.add("login-page");
          //   document.body.classList.remove("sidebar-mini");
          //   document.body.classList.remove("layout-fixed");
          //   document.body.style.height = "";
          // }
          // else{
          //   document.body.classList.remove("login-page");
          //   document.body.classList.add("sidebar-mini");
          //   document.body.classList.add("layout-fixed");
          // }
        }
      });
  }

}
