
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Blank Page</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Blank Page</li>
                </ol>
              </div>
            </div>
          </div><!-- /.container-fluid -->
        </section>
    
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <!-- <powerbi-report 
                [embedConfig]="reportConfig" 
                [cssClassName]="reportClass" 
                [phasedEmbedding]="phasedEmbeddingFlag" 
             >
                </powerbi-report> -->
<!-- 
                <powerbi-report
    [embedConfig] = {
        {
            type: 'report',
            id: '',
            embedUrl: '',
            accessToken: '',
            tokenType: models.TokenType.Embed,
                settings: {...},
        }
    }
    [cssClassName] = { 'reportClass' }
    [phasedEmbedding] = { false }
></powerbi-report> -->


              
              
            </div><!-- /.container-fluid -->
            
          </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->

