<div class="card card-secondary" style="width: 600px;">
    <div class="card-header">
      <h3 class="card-title">{{title}}</h3>
    </div>
    <!-- /.card-header -->
    <!-- form start -->
  
    <div class="card-body">
        <form [formGroup]="form" class="form">
          <div class="form-group">
              <label>Nombre del tipo de Cuestionario</label>
              <input type="text" formControlName="nombre" class="form-control">
          </div>

        </form>
        <div [ngClass]="!form.valid? 'alert-danger': 'alert-success'" class="alert alert-campos " role="alert">
          
        <i *ngIf="!form.valid" class="icon fas fa-ban"></i>
            <i *ngIf="form.valid" class="icon fas fa-check"></i> 
          {{!form.valid? 'Verifica los datos': 'Los datos están completos'}}
        </div>
    
        <!-- <div [ngClass]="!form.valid? ' callout-danger': ' callout-success'" class="callout">
          <h5> <i *ngIf="!form.valid" class="icon fas fa-ban"></i>
            <i *ngIf="form.valid" class="icon fas fa-check"></i> 
            I am a danger callout!</h5>
          
          <p>{{!form.valid? 'Verifica los datos': 'Los datos están completos'}} </p>
        </div> -->
      </div>
    <!-- /.card-body -->
  
  
    <div class="card-footer">
      <button type="button" class="btn btn-secondary float-right" (click)="guardar()">Guardar</button>
      <button type="button" class="btn btn-default float-right" (click)="cerrar()">Cancelar</button>
      
    </div>
  
  </div>