import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParametrosRoutingModule } from './parametros-routing.module';
import { ControlesComponent } from './controles/controles.component';
import { TablasComponent } from './tablas/tablas.component';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyClpPipe } from 'src/app/shared/pipes/currency-clp.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

@NgModule({
  declarations: [
    // PowerBIEmbedModule,
    ControlesComponent,
    TablasComponent,
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    ParametrosRoutingModule,
    SharedModule,
    DataTablesModule,
    NgbModule
  ]
})
export class ParametrosModule { }
