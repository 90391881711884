import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyClpPipe } from './pipes/currency-clp.pipe';
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { ProductoModalComponent } from './components/api/producto-modal/producto-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { UsuarioModalComponent } from './components/usuario-modal/usuario-modal.component';
import { AlertaModalComponent } from './components/alerta-modal/alerta-modal.component';
import { CuestionarioModalComponent } from './components/cuestionario-modal/cuestionario-modal.component';
import { TerminoModalComponent } from './components/termino-modal/termino-modal.component';
import { RespuestaModalComponent } from './components/respuesta-modal/respuesta-modal.component';
import { EncuestaModalComponent } from './components/encuesta-modal/encuesta-modal.component';



@NgModule({
  declarations: [CurrencyClpPipe,OnlynumberDirective, ProductoModalComponent, UsuarioModalComponent, AlertaModalComponent, CuestionarioModalComponent, TerminoModalComponent, RespuestaModalComponent, EncuestaModalComponent],
  imports: [
    CommonModule,
    NgxCurrencyModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[CurrencyClpPipe,OnlynumberDirective, ProductoModalComponent]
})
export class SharedModule { }
