import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ProductoModel } from 'src/app/Models/producto.model';
import { ProductoService } from 'src/app/services/productos.service';
import { ProductoModalComponent } from 'src/app/shared/components/api/producto-modal/producto-modal.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DatatablesService } from 'src/app/shared/services/datatables.service';

@Component({
  selector: 'app-tablas',
  templateUrl: './tablas.component.html',
  styleUrls: ['./tablas.component.css'],
})
export class TablasComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject();

  lsProductos: ProductoModel[] = []; 

  constructor(
    public _tables: DatatablesService,
    private _productoService: ProductoService,
    private _modalService: NgbModal,
    private _alertaService:AlertService
  ) {
    // this._tables.dtOptions.columnDefs = [
    //   { "width": "100px!important", "targets": "0" }
    //   ]
  }

  ngOnInit(): void {
    this.cargarProductos();
  }
  cargarProductos() {
    this._productoService.listar().subscribe((res) => {
      this.lsProductos = res;
      this.renderGrilla();
    });
  }

  renderGrilla() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }

  eliminar(id){
    let self= this;
    this._alertaService.confirmar("¿Desea eliminar el registro?",
    function(){
      // self._productoService.eliminar(id)
      // .subscribe(res=>{
      //   self.cargarProductos();
      // })
    });
  }

  editar(id){

    const modalRef = this._modalService.open(ProductoModalComponent, { windowClass: 'custom-modal-container' });
    const comp = modalRef.componentInstance as ProductoModalComponent;
    comp.idProducto = id;

    modalRef.result.then((data) => {
      console.log("data return modal",data);
      if(data.Codigo >0){
        this.cargarProductos();
      }
        
      })
  }

  
}
