<div class="card card-secondary" style="width: 600px">
  <div class="card-header">
    <h3 class="card-title">
      {{title}}
    </h3>
  </div>
  <!-- /.card-header -->
  <!-- form start -->

  <div class="card-body">
    <form [formGroup]="form" class="form">
      <div class="form-group">
        <label>Usuario*</label>
        <input type="text" class="form-control" formControlName="userName" />
      </div>

      <div class="form-group">
        <label>Password*</label>
        <input
          type="password"
          class="form-control"
          formControlName="contrasena"
        />
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Nombre* </label>
            <input type="text" class="form-control" formControlName="nombre" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Apellido* </label>
            <input
              type="text"
              class="form-control"
              formControlName="apellido"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Correo*</label>
        <input type="text" email="true" class="form-control" formControlName="correo" />
      </div>

      <div class="form-group">
        <label>Rut*</label>

        <input
          type="text"
          class="input-rut form-control"
          name="rut"
          id="rut"
          formControlName="rut"
          placeholder="14569484-1"
          onkeypress="return ((event.charCode == 45) ||
                                        (event.charCode >= 48 && event.charCode <= 57) ||
                                        (event.charCode == 75) || 
                                        (event.charCode == 107))"
        />
      </div>
      <div class="form-group">
        <label>Rol*</label>

        <select class="custom-select" formControlName="rol" id="tipoHurto">
          <option *ngFor="let rol of this.listaRoles" [ngValue]="rol.id">
            {{ rol.nombre }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>Teléfono*</label>
        <input type="text"
        minlength="9"
        id="telefono"
         onkeypress="return (
                                        (event.charCode >= 48 && event.charCode <= 57) 
                                        ||
                                        (event.charCode == 43) 
                                       )"
        class="form-control" formControlName="telefono" />
      </div>

      <!-- <div class="form-group">
            <label>Rol</label>
            <div class="text-danger" id="comunaError"
             style="text-align: left; display: none;" >
                Debe seleccionar un ol
            </div>
            <select class="custom-select" id="rol" formControlName="rol" >
               
            </select>
        </div>         -->
    </form>
    <div
      [ngClass]="!form.valid ? 'alert-danger' : 'alert-success'"
      class="alert alert-campos"
      role="alert"
    >
      <i *ngIf="!form.valid" class="icon fas fa-ban"></i>
      <i *ngIf="form.valid" class="icon fas fa-check"></i>
      {{ !form.valid ? "Verifica los datos" : "Los datos están completos" }}
    </div>

    <!-- <div [ngClass]="!form.valid? ' callout-danger': ' callout-success'" class="callout">
        <h5> <i *ngIf="!form.valid" class="icon fas fa-ban"></i>
          <i *ngIf="form.valid" class="icon fas fa-check"></i> 
          I am a danger callout!</h5>
        
        <p>{{!form.valid? 'Verifica los datos': 'Los datos están completos'}} </p>
      </div> -->
  </div>
  <!-- /.card-body -->

  <div class="card-footer">
    <button
      type="button"
      class="btn btn-secondary float-right"
      (click)="guardar()"
    >
      Guardar
    </button>
    <button
      type="button"
      class="btn btn-default float-right"
      (click)="cerrar()"
    >
      Cancelar
    </button>
  </div>
</div>
