<div class="card card-secondary" style="width: 600px">
  <div class="card-header">
      <h3 class="card-title">{{title}}</h3>
  </div>
  <!-- /.card-header -->
  <!-- form start -->

  <div class="card-body">
    <form [formGroup]="form" class="form">
      <div class="form-group">
        <label>Nombre Alerta</label>
        <input readonly="true" type="text" class="form-control" formControlName="nombre" />
      </div>

      <div class="form-group">
        <label>Destinatario</label>
        <input
          type="email"
          class="form-control"
          formControlName="correoCopiaDestino"
        />
      </div>

      <div class="form-group">
        <label>Remitente</label>
        <input
          type="email"
          class="form-control"
          formControlName="correoRemite"
        />
      </div>

      <div class="form-group">
        <label>Correo destino cambio de estado</label>
        <input
          type="email"
          class="form-control"
          formControlName="correoDestinoCambioEstado"
        />
      </div>

      <div class="form-group">
        <label>Correo Destino no Cambio de Estado</label>
        <input
          type="email"
          class="form-control"
          formControlName="correoDestinoNoCambioEstado"
        />
      </div>

      <div class="form-group">
        <label>Texto correo</label>
        <input
          type="text"
          class="form-control"
          formControlName="textoCorreo"
        />
      </div>
      <div class="form-group" *ngIf="alert">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customSwitch1"
            [checked]="alert.isActivo"
          />
          <label class="custom-control-label" for="customSwitch1">Activo</label>
        </div>
      </div>
    </form>
    <div
      [ngClass]="!form.valid ? 'alert-danger' : 'alert-success'"
      class="alert alert-campos"
      role="alert"
    >
      <i *ngIf="!form.valid" class="icon fas fa-ban"></i>
      <i *ngIf="form.valid" class="icon fas fa-check"></i>
      {{ !form.valid ? "Verifica los datos" : "Los datos están completos" }}
    </div>

    <!-- <div [ngClass]="!form.valid? ' callout-danger': ' callout-success'" class="callout">
        <h5> <i *ngIf="!form.valid" class="icon fas fa-ban"></i>
          <i *ngIf="form.valid" class="icon fas fa-check"></i> 
          I am a danger callout!</h5>
        
        <p>{{!form.valid? 'Verifica los datos': 'Los datos están completos'}} </p>
      </div> -->
  </div>
  <!-- /.card-body -->

  <div class="card-footer">
    <button
      type="button"
      class="btn btn-secondary float-right"
      (click)="guardar()"
    >
      Guardar
    </button>
    <button
      type="button"
      class="btn btn-default float-right"
      (click)="cerrar()"
    >
      Cancelar
    </button>
  </div>
</div>
