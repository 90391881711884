import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyclp'
})
export class CurrencyClpPipe implements PipeTransform {

  transform(value: any, decimals: number = 0, symbol = '$'): any {
    const amount: number = value ? parseFloat(value) : 0;
    const numberFormated = this.number_format(value, decimals);
    return symbol + numberFormated;
  }

  number_format(amount, decimals): string {
    //ref: https://gist.github.com/jrobinsonc/5718959

    amount += '';
    amount = parseFloat(amount.replace(/[^0-9\.]/g, ''));

    decimals = decimals || 0;
    if (isNaN(amount) || amount === 0)
      return parseFloat('0').toFixed(decimals);
    amount = '' + amount.toFixed(decimals);

    var amount_parts = amount.split('.'),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');

    return amount_parts.join(',');
  }

}
