import { Injectable, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatatablesService {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  
  dtOptions: DataTables.Settings = {
    searching: true,
    responsive: true,  
    order:[],
    ordering:true,
    language: {
      lengthMenu: "registros por página _MENU_",
      // zeroRecords: "No se encontraron datos",
      zeroRecords: " ",
      info: "página _PAGE_ de _PAGES_",
      infoEmpty: "Ningún elemento coincide",
      search:"buscar",

      paginate:{
        first: '<<',
        last: '>>',
        next: '>',
        previous: '<'
      },
    },
    
    pagingType:'full_numbers',
    dom: 'Bfrtlp',
    // buttons: [
    //   {
    //     extend: 'excelHtml5',
    //     text: '<div class="export">  Exportar  <i class="fas fa-file-download"></i></div>',
    //     titleAttr: 'Excel',
    //     exportOptions: {
    //       columns: ['th:not(.noExportable)']
    //     }
    //   }
    // ]
  };
  
  dtTrigger: Subject<any> = new Subject();
  
  constructor() { }

  public rerender(dtElement: DataTableDirective): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
   }
   
}
