import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CuestionarioModel } from 'src/app/Models/cuestionario.model';
import { TerminoModel } from 'src/app/Models/termino.model';
import { ApiService } from 'src/app/services/api.service';
import { ProductoService } from 'src/app/services/productos.service';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-termino-modal',
  templateUrl: './termino-modal.component.html',
  styleUrls: ['./termino-modal.component.css']
})
export class TerminoModalComponent implements OnInit {

  @Input() termino: TerminoModel;
  @Input() title: string = "Agregar Termino";
  form: FormGroup;

  constructor(
    private _serviceProducto: ProductoService,
    private _serviceApi: ApiService,
    private _alertaService: AlertService,
    public _activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
  ) {

    this.crearFormulario();
  }

  ngOnInit(): void {
    if (this.termino) {
      this.cargarFormulario();

    } else {
      this.crearFormulario();

    }
  }

  crearFormulario() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      descripcion: new FormControl("", Validators.required)
    })
  }

  cargarFormulario() {
    this.form.patchValue({
      nombre: this.termino.nombre,
      descripcion: this.termino.descripcion,
    });
  }

  guardar() {
    if (this.form.valid) {
      this._alertaService.inicioCarga();
      let item: TerminoModel = this.form.getRawValue();
      let self = this;

      if (this.termino) {
        //this._serviceApi.getJkt().subscribe(data => {
        this._serviceApi.putUpdateTermino(localStorage.getItem('tokenOauth'), this.termino.id, item).subscribe((res) => {
          if (res.success) {
            this._alertaService.exito('Actualizado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          (err) => {
            console.log(err)
            if (!err.ok) {
              this.cerrar();
              this.authenticationService.logout();
            }
          }
        )
        //})
        // this._serviceApi.putUpdateTermino()
      } else {
        //this._serviceApi.getJkt().subscribe(data => {
        this._serviceApi.postCreateTermino(localStorage.getItem('tokenOauth'), item).subscribe((res) => {
          if (res.success) {
            this._alertaService.exito('Creado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
          else {
            this._alertaService.error(res.message);
          }
        },
          (err) => {
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
            return this._alertaService.error(err.error.message);
          }
        )
        //})
      }
    }
    else {
      this._alertaService.error("Debe completar los campos obligatorios");
    }
  }

  cerrar() {
    this._activeModal.close({ Codigo: 0 });
  }


}
