import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuModel } from 'src/app/Models/login.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  public listaMenu = [];
  public listaMantenedor = [];
  public listaReporte = [];
  public listaDenuncia = [];
  cantNotificaciones: Number = 0;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    console.log('this.authService.isLoggedIn;', this.authService.isLoggedIn);
    console.log('me ejecuto', this.authService.isLoggedIn);
    this.isLoggedIn$ = this.authService.isLoggedIn;
    //definir lista para cargar menu de entrada en base a rol de usuario...
    this.cargarListaMenu('administrador');
    console.log('lista ', this.listaMantenedor);
    this.cantNotificaciones = 4;
  }

  ocultar() {
    $('#navbarNotificationCounter').hide();
  }

  //se debe crear metodo triger para que se ejecute cada cierto tiempo para cargar nuevamente la
  //cantidad de notificaciones que estan sin leer.
  obtieneDenuncias() {
    this.cantNotificaciones = 10;
  }
  public  obtieneNombre() {
  
    return localStorage.getItem('username');

  }
  public  obtieneRol() {
    return localStorage.getItem('rol');
  }
  cargarListaMenu(usuario: string) {
    if (this.authService.currentUserValue == null) {

      this.authService.logout();
    }
    else {
      let menu = new MenuModel();
      var rol =localStorage.getItem("rol");
      rol= rol.trim().toLowerCase();
       if (rol == 'administrador') {
        //se debe crear objeto en api o aca en aplicacivo de mantenedor
        menu.nombre = 'Terminos';
        menu.url = 'mantenedor/terminos';
        this.listaMantenedor.push(menu);
        menu = new MenuModel();
        menu.nombre = 'Cuestionario';
        menu.url = 'mantenedor/cuestionario';
        this.listaMantenedor.push(menu);

        menu = new MenuModel();
        menu.nombre = 'Encuestas';
        menu.url = 'mantenedor/encuestas';
        this.listaMantenedor.push(menu);

        menu = new MenuModel();
        menu.nombre = 'Respuestas';
        menu.url = 'mantenedor/respuestas';
        this.listaMantenedor.push(menu);


        menu = new MenuModel();
        menu.nombre = 'Usuario';
        menu.url = 'mantenedor/usuario';
        this.listaMantenedor.push(menu);
        menu = new MenuModel();

        menu.nombre = 'Alertas';
        menu.url = 'mantenedor/alertas';
        this.listaMantenedor.push(menu);



        //se debe crear objeto en api o aca en aplicacivo de reportes
        menu = new MenuModel();
        menu.nombre = 'Pago por Denuncias';
        menu.url = 'reportes/pago';
        this.listaReporte.push(menu);
        menu = new MenuModel();
        menu.nombre = 'Detalle de Denuncias';
        menu.url = 'reportes/detalle';
        this.listaReporte.push(menu);
        menu = new MenuModel();
        menu.nombre = 'Dashboard';
        menu.url = 'reportes/dashboard';
        this.listaReporte.push(menu);

        //se debe crear objeto en api o aca en aplicacivo de denuncias
        menu = new MenuModel();
        menu.nombre = 'Realizar Denuncia';
        menu.url = 'proceso/denuncia';
        this.listaDenuncia.push(menu);

      } else if (rol == 'operador') {
        menu.nombre = 'Dashboard';
        menu.url = 'reportes/dashboard';
        this.listaReporte.push(menu);
      } else if (rol == 'cliente') {
        menu.nombre = 'Realizar Denuncia';
        menu.url = 'proceso/denuncia';
        this.listaDenuncia.push(menu);
      } else if (rol == 'analista') {
        
        menu.nombre = 'Pago por Denuncias';
        menu.url = 'reportes/pago';
        this.listaReporte.push(menu);

        menu = new MenuModel();
        menu.nombre = 'Detalle de Denuncias';
        menu.url = 'reportes/detalle';
        this.listaReporte.push(menu);

        menu = new MenuModel();
        menu.nombre = 'Dashboard';
        menu.url = 'reportes/dashboard';
        this.listaReporte.push(menu);

        menu = new MenuModel();
        menu.nombre = 'Realizar Denuncia';
        menu.url = 'proceso/denuncia';
        this.listaDenuncia.push(menu);
       
      } else if (rol == 'denunciante') {
        menu.nombre = 'Realizar Denuncia';
        menu.url = 'proceso/denuncia';
        this.listaDenuncia.push(menu);
        menu = new MenuModel();
        menu.nombre = 'Detalle de Denuncias';
        menu.url = 'reportes/detalle';
        this.listaReporte.push(menu);
      } else if (rol == 'gestor') {
        menu.nombre = 'Realizar Denuncia';
        menu.url = 'proceso/denuncia';
        this.listaDenuncia.push(menu);
        menu = new MenuModel();
        menu.nombre = 'Detalle de Denuncias';
        menu.url = 'reportes/detalle';
        this.listaReporte.push(menu);
      } else {
        menu.nombre = 'Realizar Denuncia';
        menu.url = 'proceso/denuncia';
        this.listaDenuncia.push(menu);
        menu = new MenuModel();
        menu.nombre = 'Detalle de Denuncias';
        menu.url = 'reportes/detalle';
        this.listaReporte.push(menu);
      }

    }

  }
}
