import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { CuestionarioModel, EncuestaModel, RespuestaModel } from 'src/app/Models/cuestionario.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioRol } from 'src/app/Models/usuario.model';
import { AlertaModel } from 'src/app/Models/alerta.model';
import { AuthenticationService } from 'src/app/services/authentication.service';


@Component({
  selector: 'app-encuesta-modal',
  templateUrl: './encuesta-modal.component.html',
  styleUrls: ['./encuesta-modal.component.css']
})
export class EncuestaModalComponent implements OnInit {
  @Input() encuesta: EncuestaModel;
  @Input() alert: AlertaModel;
  form: FormGroup;
  listaCuestionarios: CuestionarioModel[] = [];
  listaRespuesta: RespuestaModel[] = [];
  title: string;

  constructor(
    private _alertaService: AlertService,
    public _activeModal: NgbActiveModal,
    private _serviceApi: ApiService
    , private authenticationService: AuthenticationService,
  ) {

    this.crearFormulario();
  }

  ngOnInit(): void {
    this.initData();

    if (this.encuesta) {
      this.alert = new AlertaModel();
      this.cargarFormulario();

    } else {
      this.crearFormulario();
    }
    setTimeout(() => {
      if (this.encuesta) {
        $("#customSwitch1").prop("checked", this.encuesta.isActivo);
      }

    },
      500);
  }

  crearFormulario() {
    this.form = new FormGroup({
      tipoCuestionario: new FormControl("", Validators.required),

      pregunta: new FormControl("", Validators.required),
      tipoRespuesta: new FormControl("", Validators.required),
      opciones: new FormControl("", Validators.nullValidator),
      observacion: new FormControl("", Validators.nullValidator),
      respuesta: new FormControl("", Validators.required)
    })
  }

  cargarFormulario() {

    this.form.patchValue({
      tipoCuestionario: this.encuesta.tipoCuestionario,
      usuario: localStorage.getItem('userid'),
      pregunta: this.encuesta.pregunta,
      tipoRespuesta: this.encuesta.tipoRespuesta,
      opciones: this.encuesta.opciones,
      observacion: this.encuesta.observacion,
      respuesta: this.encuesta.respuesta,
    });


  }

  initData() {
    this.listaCuestionarios = JSON.parse(localStorage.getItem("listCuestionarios"));
    this.listaRespuesta = JSON.parse(localStorage.getItem("listaRespuesta"));

    if (this.listaRespuesta == null || this.listaCuestionarios == null) {
      //this._serviceApi.getJkt().subscribe(data => {
      if (this.listaRespuesta == null) {
        this._serviceApi.getRespuestas(localStorage.getItem('tokenOauth')).subscribe((res) => {
          this.listaRespuesta = res;
          localStorage.removeItem("listaRespuesta");
          localStorage.setItem("listaRespuesta", JSON.stringify(this.listaRespuesta));

        },
          (err) => {
            console.log(err)
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
          }
        )
      }

      if (this.listaCuestionarios == null) {
        this._serviceApi.getCuestionarios(localStorage.getItem('tokenOauth')).subscribe((res) => {
          this.listaCuestionarios = res;
          localStorage.removeItem("listCuestionarios");
          localStorage.setItem("listCuestionarios", JSON.stringify(this.listaCuestionarios));

        },
          (err) => {
            console.log(err)
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
          }
        )
      }
      //});
    }
  }

  guardar() {


    console.log(this.form)
    if (this.form.valid) {

      this._alertaService.inicioCarga();

      let item: EncuestaModel = this.form.getRawValue();
      let self = this;

      if (this.encuesta) {
        //this._serviceApi.getJkt().subscribe(data => {
        console.log(item);
        item.isActivo = $('#customSwitch1').is(":checked");
        item.usuario = localStorage.getItem('userid');

        this._serviceApi.putUpdateEncuesta(localStorage.getItem('tokenOauth'), this.encuesta.id, item).subscribe((res) => {
          if (res.success) {
            this._alertaService.exito('Actualizado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        }, error => {
          console.log(error);
          if (!error.ok) {
            this.cerrar();
            return this.authenticationService.logout();
          }
          this._alertaService.error();

        }
        )
        //})
      } else {
        item.usuario = '1';
        item.isActivo = true;
        //this._serviceApi.getJkt().subscribe(data => {
        this._serviceApi.postCreateEncuesta(localStorage.getItem('tokenOauth'), item).subscribe((res: any) => {
          if (res.success) {
            this._alertaService.exito('Creado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          err => {
            console.log(err);
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
            this._alertaService.error();
          }
        )
        //})
      }


    } else {
      this._alertaService.error("Debe completar los campos obligatorios");
    }
  }

  cerrar() {
    this._activeModal.close({ Codigo: 0 });
  }
}
